import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { injectIntl, FormattedMessage } from 'gatsby-plugin-intl'

// Components
import Container from '../../components/Container'
import Footer from '../../components/Footer'
import Layout from '../../components/Layout'
import SEO from '../../components/SEO'

// ================================================================================================

const Privacy = ({ intl }) => {
  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: 'privacy.title' })} />

      <Container>
        <BackHomeLink to="/">
          <FormattedMessage id="legal.backHome" />
        </BackHomeLink>

        <Heading level="1">
          <FormattedMessage id="privacy.title" />
        </Heading>

        <Heading level="2">App Darts Scorer 180</Heading>
        <ExternalLink
          href="https://www.iubenda.com/privacy-policy/76910708"
          rel="noopener noreferrer"
          target="_blank"
        >
          Privacy Darts Scorer 180 App
        </ExternalLink>
        <ExternalLink
          href="https://www.iubenda.com/privacy-policy/51118421"
          rel="noopener noreferrer"
          target="_blank"
        >
          Datenschutzerklärung Darts Scorer 180 App
        </ExternalLink>

        <Heading level="2">Website / Webseite dartsscorer.app</Heading>
        <ExternalLink
          href="https://www.iubenda.com/privacy-policy/23641913"
          rel="noopener noreferrer"
          target="_blank"
        >
          Privacy website dartsscorer.app
        </ExternalLink>
        <ExternalLink
          href="https://www.iubenda.com/privacy-policy/57772210"
          rel="noopener noreferrer"
          target="_blank"
        >
          Datenschutzerklärung Webseite dartsscorer.app
        </ExternalLink>
      </Container>

      <Footer />
    </Layout>
  )
}

// ================================================================================================

const BackHomeLink = styled(Link)`
  display: inline-block;
  font-size: 1rem;
  color: var(--primary-color);
  margin-bottom: 2rem;
  text-decoration: none;
`

const Heading = styled.h1`
  font-size: ${({ level }) => (level === '1' ? '2rem' : level === '2' ? '1.5rem' : '1rem')};
  color: var(--text-color);
  margin: ${({ level }) =>
    level === '1' ? '0 0 3rem' : level === '2' ? '3rem 0 1.5rem 0' : '1rem 0 0.5rem'};

  @media screen and (min-width: 600px) {
    font-size: ${({ level }) => (level === '1' ? '2.5rem' : level === '2' ? '1.75rem' : '1rem')};
  }
`

const ExternalLink = styled.a`
  font-size: 1rem;
  line-height: 1.4;
  margin-bottom: 1rem;
  font-weight: bold;
  color: var(--text-color);
  text-decoration: none;
  display: block;

  &:hover {
    color: var(--primary-color);
  }
`

// ================================================================================================

export default injectIntl(Privacy)
